export default {
  address_district_id: null,
  address_house: null,
  address_info: null,
  address_street: null,
  agreement_date: null,
  agreement_land_usage_type_id: null,
  agreement_number: null,
  area_id: null,
  area_name: null,
  area_report_name: null,
  audit_doc_date: null,
  audit_doc_number: null,
  creation_date: null,
  d_object_status: 3,
  delete_comment: null,
  district_name: null,
  file_audit_doc_files: null,
  file_delete_reason: null,
  file_object_move_docs: null,
  file_remove_act_files: null,
  file_remove_act_photos: null,
  file_remove_force_files: null,
  file_remove_voluntary_files: null,
  file_remove_voluntary_photos: null,
  file_sec_remove_voluntary_files: null,
  file_sec_remove_voluntary_photos: null,
  file_utilization_files: null,
  files: [],
  fix_doc_date: null,
  fix_doc_number: null,
  fix_doc_result: null,
  full_status_name: null,
  geo_polygon: null,
  geo_type: 'point',
  guid: null,
  is_deleted: false,
  is_moved: false,
  keep_date: null,
  keep_place_id: -1,
  last_update_date: null,
  lease_violation_desc: null,
  lease_violation_doc_date: null,
  lease_violation_doc_number: null,
  lease_violation_fix_date: null,
  log: [],
  move_data: null,
  move_obj_registration_date: null,
  move_obj_registration_number: null,
  obj_registration_date: null,
  obj_registration_number: null,
  obj_type_id: null,
  object_type_name: null,
  offence: [],
  owner_email: null,
  owner_fullname: null,
  owner_id: null,
  owner_info: null,
  owner_is_known: true,
  owner_name: null,
  owner_organization: null,
  owner_position: null,
  phone_add: null,
  phone_work: null,
  place_house: null,
  place_street: null,
  property_type_id: 1,
  property_type_name: 'Муниципальная земля',
  remove_act_date: null,
  remove_act_number: null,
  remove_actual_date: null,
  remove_cost_actual: null,
  remove_cost_refund_date: null,
  remove_cost_refunded: null,
  remove_ext_date_to: null,
  remove_ext_doc_date: null,
  remove_ext_doc_number: null,
  remove_ext_doc_type_name: null,
  remove_force_bulletin_date: null,
  remove_force_bulletin_number: null,
  remove_force_doc_date: null,
  remove_force_doc_number: null,
  remove_plan_date: null,
  remove_voluntary_date_from: null,
  remove_voluntary_date_to: null,
  remove_voluntary_doc_date: null,
  remove_voluntary_doc_number: null,
  remove_voluntary_is_done: null,
  responsible_name: null,
  rn: 1,
  sec_remove_voluntary_doc_date: null,
  sec_remove_voluntary_doc_number: null,
  sec_remove_voluntary_is_done: null,
  status_change_date: null,
  status_desc: null,
  status_name: null,
  usage_type_name: null,
  utilization_date: null,
  utilization_doc_number: null,
  violation_type_id: 1,
  violation_type_name: 'незаконный объект',
  warranty_measures_info: null,
  warranty_measures_taken: false
};
